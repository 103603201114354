import * as React from 'react'
import { useState } from 'react'
import { graphql } from 'gatsby'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import parse from 'html-react-parser'

import Layout from '../components/layout'

import Image from '../components/partials/image'

import CopyIcon from '../assets/copy.inline.svg'

const OpeningPage = ({ data }) => {
	const opening = data.strapi.opening

	const [copyContact, setContact] = useState(false)

	return (
		<Layout page={data.strapi.careers} metadata={opening.metadata}>
			<div className="container pt-80 sm:pt-120 lg:pt-0">
				{/* Hero Unit */}
				<section className="py-20 lg:py-40">
					<div className="relative bg-white p-30 lg:p-60">
						<h1>{opening.title}</h1>
						<h2 className="text-grey h-200 lg:h-300">{opening.subtitle}</h2>
						<div className="absolute bottom-30 lg:bottom-60 right-30 lg:right-60 w-30 lg:w-60 h-30 lg:h-60">
							<svg
								width="60"
								height="60"
								viewBox="0 0 60 60"
								className="relative h-full w-full"
							>
								<path
									fill="transparent"
									strokeWidth="6"
									stroke="#333333"
									d="M 30 2 L 30 58"
								/>
								<path
									fill="transparent"
									strokeWidth="6"
									stroke="#333333"
									d="M 2 29 L 32 58"
								/>
								<path
									fill="transparent"
									strokeWidth="6"
									stroke="#333333"
									d="M 28 58 L 58 29"
								/>
							</svg>
						</div>
					</div>
				</section>

				{/* Need to Have Section */}
				<section className="py-20 lg:py-40">
					<div className="p w-10/12 xl:w-8/12 mx-auto py-40 pl-30">
						{parse(opening.requirements)}
					</div>
				</section>

				<section className="py-20 lg:py-40">
					<Image asset={opening.image} />
				</section>

				{/* Key Responsibities Section */}
				<section className="py-20 lg:py-40">
					<div className="mx-auto sm:w-10/12 lg:w-8/12 pb-20">
						<h4 className="text-center text-grey font-medium">{opening.tag}</h4>
						<h3 className="text-center">{opening.description}</h3>
					</div>

					<div className="p w-10/12 xl:w-8/12 mx-auto py-40 pl-30">
						{parse(opening.expectations)}
					</div>
				</section>

				{/* CTA Section */}
				<section className="py-20 lg:py-40">
					<div className="relative bg-placeholder text-center">
						<div className="lg:w-6/12 p-60 lg:py-120 lg:px-0 mx-auto">
							<h3 className="font-medium py-10">
								Send your application to{' '}
								<span>
									<a
										className="font-normal underline hover:text-blue"
										href="mailto:careers@oppositehq.com"
										rel="noreferrer"
									>
										careers@oppositehq.com
									</a>
									<CopyToClipboard
										text="careers@oppositehq.com"
										onCopy={() => {
											setContact(true)
											setTimeout(() => {
												setContact(false)
											}, 1000)
										}}
									>
										<button className="pl-10 lg:pl-15 focus:outline-none">
											<span
												className="inline-block h-30 lg:h-40 align-middle hover:text-blue"
												style={{ color: copyContact && '#009C06' }}
											>
												<CopyIcon className="relative h-full w-full  fill-current stroke-current" />
											</span>
										</button>
									</CopyToClipboard>
								</span>
							</h3>
							<h4 className="text-grey pt-20 lg:pt-40">
								Don’t forget to send your Resume/CV, your portfolio and tell us
								why you want to work with us. Shortlisted profiles hear from us
								within one week.
							</h4>
						</div>
					</div>
				</section>
			</div>
		</Layout>
	)
}

export const query = graphql`
	query Openings($id: ID!) {
		strapi {
			opening(id: $id) {
				id
				slug
				title
				subtitle
				requirements
				image {
					url
					ext
					name
					height
					width
					imageFile {
						publicURL
						childImageSharp {
							gatsbyImageData(
								width: 1290
								transformOptions: { cropFocus: CENTER, fit: COVER }
								layout: CONSTRAINED
								formats: [AUTO, WEBP, AVIF]
							)
						}
					}
				}
				tag
				description
				expectations
				metadata {
					meta_title
					meta_desc
					meta_image {
						url
						imageFile {
							publicURL
						}
					}
				}
			}
			careers: careersPage {
				footer_headline
				footer_link_url
				footer_link_text
			}
		}
	}
`

export default OpeningPage
